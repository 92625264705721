import { useState } from 'react';
import { useSelector } from 'react-redux';
import { variableSelector } from 'selectors/variableSelector';
import { usePlaces } from 'hooks/usePlaces';
import { formatDate } from 'helpers';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CriteriaSection from './CriteriaSection';
import KpiGraph from '../KpiGraph';
import LoadingOverlay from 'components/common/LoadingOverlay';

const KpiForm = ({ kpi, isLoading, onSubmit, mode = 'create' }) => {
	const { variables } = useSelector(variableSelector);
	const [isSaving, setIsSaving] = useState(false);
	// Initialize states with existing data if in edit mode
	const [formData, setFormData] = useState({
		unit_level: kpi?.unit_level || '',
		place: kpi?.place || '',
		variable_id: kpi?.variable_id || '',
		kpi_name: kpi?.kpi_name || '',
		start_date: formatDate(kpi?.start_date) || '',
		end_date: formatDate(kpi?.end_date) || '',
		target_value: kpi?.target_value || '',
		percentage_value: kpi?.percentage_value || '',
	});
	const { places } = usePlaces({unit_level: formData.unit_level});
	const [criteria, setCriteria] = useState(
		kpi?.criteria || [
			{ label: '', lower_limit: '', upper_limit: '' },
			{ label: '', lower_limit: '', upper_limit: '' }
		]
	);

	const handleFormChange = (e) => {
		const { name, value } = e.target
		setFormData((prev) => ({
			...prev,
			[name]: value
		}))
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSaving(true);

		const payload = {
			...formData,
			criteria: criteria
		}

		try {
			await onSubmit(payload);
		} catch (error) {
			console.error('Failed to save KPI:', error);
		} finally {
			setIsSaving(false);
		}
	}

	const handleCriteriaChange = (index, field, value) => {
		const newCriteria = [...criteria];
		newCriteria[index] = { ...newCriteria[index], [field]: value };
		setCriteria(newCriteria);
	}

	const addCriteria = () => {
		if (criteria.length < 5) {
			setCriteria([...criteria, { label: '', lower_limit: '', upper_limit: '' }]);
		}
	}

	const removeCriteria = (index) => {
		if (criteria.length > 2) {
			setCriteria(criteria.filter((_, i) => i !== index));
		}
	}

	return (
		<>
			<LoadingOverlay 
        show={isSaving} 
        message={mode === 'create' ? 'Creating KPI...' : 'Saving Changes...'}
      />
			<Form onSubmit={handleSubmit} id='kpiForm'>
				<KpiFormDropdown
					formData={formData}
					handleFormChange={handleFormChange}
					places={places}
					variables={variables}
				/>

				<Row>
					<KpiFormFields
						formData={formData}
						handleFormChange={handleFormChange}
						criteria={criteria}
						setCriteria={setCriteria}
						addCriteria={addCriteria}
						removeCriteria={removeCriteria}
						handleCriteriaChange={handleCriteriaChange}
					/>

					<Col xs={12} lg={8}>
						<KpiGraph data={kpi?.bar_data || []} population={kpi?.total_count} mode={mode} title={formData.kpi_name}/>
					</Col>
				</Row>

				<hr />
				<Button
					variant='success'
					type='submit'
				>
					{mode === 'edit' ? 'Save Changes' : 'Create KPI'}
				</Button>
			</Form>
		</>
	)
}

const KpiFormDropdown = ({ formData, handleFormChange, places, variables }) => {
	return (
		<Row className='mb-5'>
			<Col>
				<Form.Group>
					<Form.Label>Unit Level</Form.Label>
					<Form.Select
						name='unit_level'
						onChange={handleFormChange}
						value={formData.unit_level}
					>
						<option value=''>Select one</option>
						{['province', 'district', 'municity', 'barangay', 'precinct'].map(level => (
							<option key={level} value={level}>
								{level.charAt(0).toUpperCase() + level.slice(1)}
							</option>
						))}
					</Form.Select>
				</Form.Group>
			</Col>
			<Col>
				<Form.Group>
					<Form.Label>Place</Form.Label>
					<Form.Select
						name='place'
						onChange={handleFormChange}
						value={formData.place}
						disabled={!formData.unit_level}
					>
						<option value=''>Select one</option>
						{places.map((option) => (
							<option key={option} value={option}>{option}</option>
						))}
					</Form.Select>
				</Form.Group>
			</Col>
			<Col>
				<Form.Group>
					<Form.Label>Variable</Form.Label>
					<Form.Select
						name='variable_id'
						onChange={handleFormChange}
						value={formData.variable_id}
						disabled={!formData.variable_id}
					>
						<option value=''>Select one</option>
						{variables?.map((option) => (
							<option key={option.variable_id} value={option.variable_id}>
								{option.name}
							</option>
						))}
					</Form.Select>
				</Form.Group>
			</Col>
		</Row>
	)
}

const KpiFormFields = ({formData, handleFormChange, criteria, setCriteria, addCriteria, removeCriteria, handleCriteriaChange}) => {
	return (
		<Col xs={12} lg={4}>
			<Form.Group as={Row} className='mb-3'>
				<Form.Label column sm={3}>KPI</Form.Label>
				<Col sm={9}>
					<Form.Control
						name='kpi_name'
						value={formData.kpi_name}
						onChange={handleFormChange}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className='mb-3'>
				<Form.Label column sm={3}>Start Date</Form.Label>
				<Col sm={9}>
					<Form.Control
						name='start_date'
						type='date'
						value={formData.start_date}
						onChange={handleFormChange}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className='mb-3'>
				<Form.Label column sm={3}>End Date</Form.Label>
				<Col sm={9}>
					<Form.Control
						name='end_date'
						type='date'
						value={formData.end_date}
						onChange={handleFormChange}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className='mb-3' controlId='formHorizontalEmail'>
				<Form.Label column sm={3}>
					Target Value
				</Form.Label>
				<Col sm={9}>
					<Form.Control
						name='target_value'
						value={formData.target_value}
						onChange={handleFormChange}
						placeholder='Input actual value (% value is auto-computed)'
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className='mb-3' controlId='formHorizontalEmail'>
				<Form.Label column sm={3}>
					Percentage Value
				</Form.Label>
				<Col sm={9}>
					<Form.Control
						name='percentage_value'
						value={formData.percentage_value}
						onChange={handleFormChange}
						placeholder='Input % value (actual value is auto-computed)'
					/>
				</Col>
			</Form.Group>

			<CriteriaSection
				criteria={criteria}
				setCriteria={setCriteria}
				onAdd={addCriteria}
				onRemove={removeCriteria}
				onChange={handleCriteriaChange}
			/>
		</Col>
	)
}

export default KpiForm;
