import "./VariableModal.css";
import { Button, Col, Form, Modal, Row, Stack, Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVariableAction, updateVariableAction, getVariableListAction } from "actions/variableActions";
import { addVariableOptionAction, updateVariableOptionAction } from "actions/variableOptionActions";
import { v4 as uuidv4 } from "uuid";
import { RESOURCE_TYPES, VARIABLE_DATA_TYPES, VARIABLE_KPI_TYPES } from "constants/common";
import { getProvinceListAction } from "actions/provinceActions";
import { getMunicipalityListAction } from "actions/municipalityActions";
import { provinceSelector } from "selectors/provinceSelector";
import { municipalitySelector } from "selectors/municipalitySelector";

function VariableModal(props) {
    const { onHide, variable, show } = props;
    const dispatch = useDispatch();
    const { provinces } = useSelector(provinceSelector);
    const { municipalities } = useSelector(municipalitySelector);
    const [ formData, setFormData ] = useState({
        variable_id: variable.variable_id,
        name: variable.name,       
        type: variable.type,
        is_active: variable.is_active,
        resource_type: variable.resource_type || 'Voter',
        question: variable.question
    });
    const [ options, setOptions ] = useState(variable.variable_options);

    const handleAddOption = () => {
        setOptions((prev) => [...prev, { variable_id: variable.variable_id, name: "", is_active: true }]);
    }

    const handleToggleOption = (variableOptionId) => {
        setOptions((prev) => {
            const selectedOption = prev.find((option) => option.variable_options_id === variableOptionId);
            selectedOption.is_active = !selectedOption.is_active;
            const newOptions = prev.filter((option) => option.variable_options_id !== variableOptionId);
            newOptions.push(selectedOption);
            return newOptions;
        })
    }

    const handleOptionChange = (e, index) => {
        setOptions((prev) => {
            const updatedOptions = [...prev];
            updatedOptions[index][e.target.name] = e.target.value;
            return updatedOptions;
        });
    };

    const handleOptionSave = () => {
        options.forEach((option) => {
            if (option.variable_options_id) {
                dispatch(updateVariableOptionAction(option.variable_options_id, option));
            } else {
                dispatch(addVariableOptionAction(option));
            }
        })
    };

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const handleSubmit = async() => {
        if (variable.variable_id) {
            dispatch(updateVariableAction(formData.variable_id, formData));
        } else {
            dispatch(addVariableAction(formData));
        }

        handleOptionSave();
        dispatch(getVariableListAction());
        onHide();
    };

    useEffect(() => {
        dispatch(getVariableListAction());
        dispatch(getProvinceListAction());
        dispatch(getMunicipalityListAction());
    }, [dispatch]);

    return(
        <Modal id="variable-modal" onHide={onHide} show={show} size="lg" animation>
            <Modal.Header closeButton>Variable Profile</Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Stack gap={2}>
                        <Row>
                            <Col lg={3}><Form.Label>Name</Form.Label></Col>
                            <Col lg={9}><Form.Control name="name" onChange={handleChange} defaultValue={formData.name} placeholder="Enter a name for this variable." /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Data Type</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="type" onChange={handleChange} value={formData.type}>
                                    {VARIABLE_DATA_TYPES.map((type) => (
                                        <option key={uuidv4()} value={type}>{type}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>KPI Type</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="kpi_type" onChange={handleChange} value={formData.kpi_type}>
                                    <option value="">Not KPI</option>
                                    {VARIABLE_KPI_TYPES.map((type) => (
                                        <option key={uuidv4()} value={type}>{type}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Question</Form.Label></Col>
                            <Col lg={9}><Form.Control name="question" onChange={handleChange} defaultValue={formData.question} placeholder="Enter a question for this variable." /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Resource Type</Form.Label></Col>
                            <Col lg={9}><Form.Select name="resource_type" onChange={handleChange} value={formData.resource_type}>
                                {RESOURCE_TYPES.map((resourceType) => (
                                    <option key={uuidv4()} value={resourceType}>{resourceType}</option>
                                ))}
                            </Form.Select></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Enabled</Form.Label></Col>
                            <Col lg={9}><Form.Check name="is_active" onChange={handleChange} defaultChecked={formData.is_active} /></Col>
                        </Row>                        
                        <div className="variable-options">
                            {formData.type !== 'STRING' && options.length > 0 && <Table>                                
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Option</th>
                                        <th>Province</th>
                                        <th>Municipality/City</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>                                
                                    {options.map((option, index) => (
                                        <tr key={uuidv4()}>
                                            <td>{index + 1}</td>
                                            <td><Form.Control name="name" disabled={!option.is_active} defaultValue={option.name} onBlur={(e) => handleOptionChange(e, index)} /></td>
                                            <td>
                                                <Form.Select name="province" disabled={!option.is_active} defaultValue={option.province} onChange={(e) => handleOptionChange(e, index)}>
                                                    <option value="">Select Province</option>
                                                    {
                                                        provinces.map((province) => (
                                                            <option key={uuidv4()} value={province}>{province}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </td>
                                            <td>
                                                <Form.Select name="municity" disabled={!option.is_active} defaultValue={option.municity} onChange={(e) => handleOptionChange(e, index)}>
                                                    <option value="">Select Municipality/City</option>
                                                    {
                                                        municipalities.map((municipality) => (
                                                            <option key={uuidv4()} value={municipality}>{municipality}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                            </td>
                                            <td><Button variant="danger" size="sm" onClick={() => handleToggleOption(option.variable_options_id)}><FontAwesomeIcon icon={faPowerOff} /></Button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>}
                        </div>
                        {!variable.variable_id && <p>To add options, please create the variable first.</p>}
                        {variable.variable_id && (variable.type === "Preference" && variable.variable_options.length === 0) && <p>To add options, please click on the "Add Option" button below.</p>}
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" disabled={!variable.variable_id || formData.type === 'STRING'} onClick={handleAddOption}>Add Option</Button>
                <Button variant="success" onClick={() => handleSubmit()}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default VariableModal;