import "./FilterUsers.css";
import { Button, Modal, Container, Form, Stack } from "react-bootstrap";
import { useState } from "react";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";
import { SEXES } from "constants/common";
import { useVariables } from "hooks/useVariables";

function FilterUsers({ show, onHide }) {
    const [filters, setFilters] = useState({});
    const { provinces, districts, muniCities, precincts, barangays } = useGeopoliticalUnits({
        province: filters.province,
        district: filters.district,
        muniCity: filters.municity,
        barangay: filters.barangay,
        precinct: filters.precinct
    });
    const { variables } = useVariables();
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleClose = () => {
        setFilters({});
        onHide();
    };

    return (
        <Modal id="filterUsers" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Stack gap={2}>
                        <Form.Group>
                            <Form.Label>
                                Name
                            </Form.Label>
                            <Form.Control type="text" name="name" placeholder="Name" onChange={handleFilterChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Address
                            </Form.Label>
                            <Form.Control type="text" name="address" placeholder="Address" onChange={handleFilterChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Sex
                            </Form.Label>
                            <Form.Select name="sex" onChange={handleFilterChange}>
                                <option value="">All</option>
                                {
                                    SEXES.map((option) => (
                                        <option value={option === 'Male' ? 'M' : 'F'}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Birthdate
                            </Form.Label>
                            <Form.Control name="birthdate" type="date" onChange={handleFilterChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Province
                            </Form.Label>
                            <Form.Select name="province" onChange={handleFilterChange}>
                                <option value="">All</option>
                                {
                                    provinces.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                District
                            </Form.Label>
                            <Form.Select name="district" onChange={handleFilterChange} disabled={!filters.province}>
                                <option value="">{filters.province ? 'All' : 'Select a province first'}</option>
                                {
                                    districts.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Municipality/City
                            </Form.Label>
                            <Form.Select name="municity" onChange={handleFilterChange} disabled={!filters.district}>
                                <option value="">{filters.district ? 'All' : 'Select a district first'}</option>
                                {
                                    muniCities.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Barangay
                            </Form.Label>
                            <Form.Select name="barangay" onChange={handleFilterChange} disabled={!filters.muniCity}>
                                <option value="">{filters.muniCity ? 'All' : 'Select a municipality/city first'}</option>
                                {
                                    barangays.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Precinct
                            </Form.Label>
                            <Form.Select name="precinct" onChange={handleFilterChange} disabled={!filters.barangay}>
                                <option value="">{filters.barangay ? 'All' : 'Select a barangay first'}</option>
                                {
                                    precincts.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        {
                            variables.map((variable) => (
                                <Form.Group>
                                    <Form.Label>
                                        {variable.name}
                                    </Form.Label>
                                    <Form.Select name={variable.name} onChange={handleFilterChange}>
                                        <option value="">All</option>
                                        {
                                            variable.variable_options.map((option) => (
                                                <option value={option.variable_options_id}>{option.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            ))
                        }
                    </Stack>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onHide(filters)}>Filter</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FilterUsers;