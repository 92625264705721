import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addKpiAction } from 'actions/kpiActions';
import KpiForm from './KpiForm';
import AlertMessage from 'components/AlertMessage/AlertMessage';

const CreateKpi = () => {
  const dispatch = useDispatch();
  const [alertConfig, setAlertConfig] = useState({
    heading: '',
    message: '',
    variant: 'danger',
    show: false,
    dismissible: true // Set default value here
  });

  const showAlert = useCallback((heading, message, variant = 'danger') => {
    setAlertConfig({
      heading,
      message,
      variant,
      dismissible: true,
      show: true
    });
  }, []);

  const handleAlertClose = useCallback(() => {
    setAlertConfig((prev) => ({ ...prev, show: false }));
  }, []);

  const handleSubmit = async (data) => {
    try {
      dispatch(addKpiAction(data));
    } catch (error) {
      showAlert('Something went wrong', error.message || 'Failed to create KPI');
    }
  }

  return (
    <section id='createKpi'>
      <AlertMessage
        heading={alertConfig.heading}
        message={alertConfig.message}
        variant={alertConfig.variant}
        visibility={alertConfig.show}
        onClose={handleAlertClose}
        dismissible={alertConfig.dismissible}
      />

      <KpiForm onSubmit={handleSubmit} />
    </section>
  );
}

export default CreateKpi;
