import "./ManageUsers.css";
import { Badge, Button, Card, Col, Container, Row, Spinner, Stack, Table } from "react-bootstrap";
import { faFileImport, faFilter, faPencil, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "helpers/index";
import { useUsers } from "hooks/useUsers";
import { v4 as uuidv4 } from "uuid";
import FilterUsers from "./FilterUsers/FilterUsers";
import UserUploadMapper from "./UserUploadMapper/UserUploadMapper";
import { useVariables } from "hooks/useVariables";
import UserModal from "components/Modals/UserModal/UserModal";
import { stripBraces } from "helpers/index";

function ManageUsers() {
    const [showUploadMapper, setShowUploadMapper] = useState(false);
    const [showFilterUsers, setShowFilterUsers] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userList, setUserList] = useState([]);
    const [filters, setFilters] = useState({});
    const {
        isLoading,
        total,
        users,
        usersCurrentPage,
        usersLastPage
    } = useUsers(filters);
    const { variables } = useVariables();
    const cardRef = useRef(null);

    const handleFilterClose = (filters) => {
        setFilters(filters);
        setShowFilterUsers(false);
    };

    const handleImportUsers = () => {
        setShowUploadMapper(true);
    };

    const handleReset = () => {
        setFilters(() => {
            setUserList([]);
            return {};
        })
    };

    const handleScroll = useCallback(() => {
        if (cardRef.current.scrollHeight > (cardRef.current.offsetHeight + cardRef.current.scrollTop) || isLoading) return;
        if (usersCurrentPage < usersLastPage) {
            setFilters((prev) => ({
                ...prev,
                page: usersCurrentPage + 1
            }));
        };
    }, [isLoading, usersCurrentPage, usersLastPage]);

    useEffect(() => {
        cardRef.current.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, handleScroll]);

    useEffect(() => {
        if (users && userList.length < total) {
            setUserList((prev) => [...prev, ...users]);
        };
    }, [users, total, userList.length]);

    return (
        <section id="manageUsers">
            <Container>
                <FilterUsers show={showFilterUsers} onHide={handleFilterClose} />
                <UserUploadMapper show={showUploadMapper} onHide={() => setShowUploadMapper(false)} />
                {selectedUser && <UserModal show={selectedUser} user={selectedUser} onHide={() => setSelectedUser(null)} />}
                <Row>
                    <Col>
                        <Card className="view">
                            <Card.Header>
                                <Stack className="d-flex justify-content-between" direction="horizontal" gap={2}>
                                    <div>
                                        <span>Manage Users</span>{" "}
                                        <Badge bg="success" size="sm">{userList.length}/{total}</Badge>
                                    </div>
                                    <div>
                                        <Button variant="success" size="sm" onClick={handleImportUsers}><FontAwesomeIcon icon={faFileImport} /></Button>{" "}
                                        {/* <Button variant="outline-success" size="sm"><FontAwesomeIcon icon={faPlus} /></Button>{" "} */}
                                        <Button variant="primary" size="sm" onClick={() => setShowFilterUsers(true)}><FontAwesomeIcon icon={faFilter} /></Button>{" "}
                                        <Button variant="outline-primary" size="sm" onClick={handleReset}><FontAwesomeIcon icon={faRotateLeft} /></Button>
                                    </div>
                                </Stack>
                            </Card.Header>
                            <Card.Body className="wrapper" ref={cardRef}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Name</th>
                                            <th>Sex</th>
                                            <th>Address</th>
                                            <th>Birthdate</th>
                                            <th>Assigned Provinces</th>
                                            <th>Assigned Districts</th>
                                            <th>Assigned Municipalities/Cities</th>
                                            <th>Assigned Barangays</th>
                                            <th>Assigned Precincts</th>
                                            {
                                                variables.map((variable) => (
                                                    <th key={uuidv4()}>{variable.name}</th>
                                                ))
                                            }
                                            <th className="sticky-col first-col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList.map((user, index) => (
                                                <tr key={user.user_id}>
                                                    <td>{index + 1}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.sex}</td>
                                                    <td>{user.address}</td>
                                                    <td>{formatDate(user.birthdate)}</td>
                                                    <td>{stripBraces(user.provinces)}</td>
                                                    <td>{stripBraces(user.districts)}</td>
                                                    <td>{stripBraces(user.municities)}</td>
                                                    <td>{stripBraces(user.barangays)}</td>
                                                    <td>{stripBraces(user.precincts)}</td>
                                                    {
                                                        variables.map((variable) => (
                                                            <td key={uuidv4()}></td>
                                                        ))
                                                    }
                                                    <td className="sticky-col first-col">
                                                        <Button size="sm" onClick={() => setSelectedUser(user)}><FontAwesomeIcon icon={faPencil} /></Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <Stack gap={2} className="d-flex justify-content-center text-center">
                                    {isLoading && <Spinner animation="border" role="status" />}
                                    {!isLoading && userList.length === 0 && <span>No users found.</span>}
                                    {!isLoading && userList.length > 0 && usersCurrentPage === usersLastPage && <span>End of List</span>}
                                </Stack>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ManageUsers;