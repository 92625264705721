import "./GatherForm.css";
import { useEffect, useState } from "react";
import { Button, Container, Form, Stack } from "react-bootstrap";
import pdf from "assets/informed_consent.pdf";
import SignatureCanvas from "react-signature-canvas";
import { variableSelector } from "selectors/variableSelector";
import { useDispatch, useSelector } from "react-redux";
import { getVariableListAction } from "actions/variableActions";
import { v4 as uuidv4 } from 'uuid';
import { getVoter, updateVoterAction } from "actions/voterActions";
import { voterSelector } from "selectors/voterSelector";

function GatherForm(props) {
    const { voterId, onFinish } = props;
    const dispatch = useDispatch();
    const interviewId = uuidv4();
    const [formData, setFormData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const { variables } = useSelector(variableSelector);
    const { voter } = useSelector(voterSelector);

    useEffect(() => {
        dispatch(getVariableListAction({ resource_type: 'Voter' }));
        dispatch(getVoter(voterId));
    }, [dispatch, voterId]);

    const renderConsentPage = () => {
        return (
            <div className="informed-consent">
                <iframe className="w-100 h-75" src={pdf} title="Informed Consent" />
                <div className="border border-secondary rounded">
                    <SignatureCanvas canvasProps={{ className: 'w-100 h-100' }} />
                </div>
            </div>
        )
    };

    const renderVariable = (variable) => {
        const { variable_id, variable_options, type } = { ...variable };
        const options = variable_options.filter((opt) => (opt.province === voter.province || opt.province === null) && (opt.municity === voter.municity || opt.municity === null))
        return (
            <div>
                <Form.Group>
                    <h3>{variable.question}</h3>
                    {type === 'ARRAY' ? renderOptions(options, handleChange) : renderTextField(variable_id, handleChange)}
                </Form.Group>
            </div>
        )
    };

    const renderTextField = (variable_id, callback) => {
        return (
            <Form.Control key={uuidv4()} name={variable_id} onBlur={callback} defaultValue={getValue(variable_id)} />
        )
    };

    const renderOptions = (options, callback) => {
        return (
            options.map((option) => (
                <Form.Check
                    key={uuidv4()}
                    name={option.variable_id}
                    onChange={callback}
                    label={option.name}
                    type="radio"
                    value={option.name}
                    checked={getValue(option.variable_id, option)}
                />
            ))
        )
    };

    const getValue = (variable_id, option) => {
        const responseIndex = formData.findIndex((res) => res.variable_id === variable_id);
        if (responseIndex !== -1) {
            return option ? formData[responseIndex].answer === option.name : formData[responseIndex].answer
        } else {
            return ''
        }
    }

    const handleBack = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    const handleNext = () => {
        if (currentIndex < (variables.length - 1)) {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    const handleChange = (e) => {
        setFormData((prev) => {
            const updated = [...prev];
            const responseIndex = updated.findIndex((res) => res.variable_id === e.target.name);
            if (responseIndex === -1) {
                updated.push({
                    variable_id: e.target.name,
                    interview_id: interviewId,
                    answer: e.target.value
                })
            } else {
                updated[responseIndex] = {
                    ...prev[responseIndex],
                    answer: e.target.value
                }
            }
            return updated;
        })
    };

    const renderNavigation = () => {
        return (
            <div className="w-100 d-flex justify-content-end my-2">
                <Stack gap={2} direction="horizontal">
                    {currentIndex !== -1 && <Button onClick={handleBack}>Back</Button>}
                    {currentIndex !== (variables.length - 1) && <Button onClick={handleNext}>Next</Button>}
                    {currentIndex === (variables.length - 1) && <Button variant="success" onClick={handleSubmit}>Finish</Button>}
                </Stack>
            </div>
        )
    }

    const handleSubmit = () => {
        dispatch(updateVoterAction(voterId, {
            voter_responses: formData
        }));
        onFinish();
    };

    return (
        <Container id="gather-form">
            <div className="questionnaire">
                <div>
                    {
                        currentIndex === -1 ? renderConsentPage() : renderVariable(variables[currentIndex])
                    }
                </div>
                {
                    renderNavigation()
                }
            </div>
        </Container>
    );
};

export default GatherForm;