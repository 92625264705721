import "./Gather.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Card, Container, Stack, Table } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";
import { getVoters } from "actions/voterActions";
import { voterSelector } from "selectors/voterSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPerson, faXmark } from "@fortawesome/free-solid-svg-icons";
import GatherForm from "./GatherForm/GatherForm";
import { convertPgArray } from "helpers";
import { getUser, getUsers } from "actions/userActions";
import { userSelector } from "selectors/userSelector";
import { sessionSelector } from "selectors/sessionSelector";

function Gather() {
    const dispatch = useDispatch();
    const { voters } = useSelector(voterSelector);
    const [voterId, setVoterId] = useState(null);
    const [filters, setFilters] = useState({});
    const { user, users } = useSelector(userSelector);
    const { userId } = useSelector(sessionSelector);
    const { provinces, districts, muniCities, barangays, precincts } = useGeopoliticalUnits(filters);


    const fetchData = useCallback(() => {
        dispatch(getVoters({ ...filters, voterId }));
        dispatch(getUsers(filters));
    }, [dispatch, filters, voterId]);

    const handleClearFilter = (filter) => {
        setFilters((prev) => {
            switch (filter) {
                case "province":
                    return {};
                case "district":
                    return { province: prev['province'] };
                case "muniCity":
                    return { province: prev['province'], district: prev['district'] };
                case "barangay":
                    return { province: prev['province'], district: prev['district'], muniCity: prev['muniCity'] };
                case "precinct":
                    return { province: prev['province'], district: prev['district'], muniCity: prev['muniCity'], barangay: prev['barangay'] };
                default:
                    return {};
            }
        });
    };

    useEffect(() => {
        if (filters.precinct) {
            fetchData();
        }
    }, [filters.precinct, fetchData]);

    useEffect(() => {
        setFilters({});
        dispatch(getUser(userId));
    }, [dispatch, userId]);

    const renderTable = (items, unit) => {
        return <Table striped bordered hover>
            <tbody>
                {
                    items.map((item) => (
                        <tr key={uuidv4()} onClick={() => setFilters((prev) => ({ ...prev, [unit]: item }))}>
                            <td>{item}</td>
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    };

    return (
        <section id="gather">
            <Container>
                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                            <span>My Voters</span>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="d-flex flex-wrap gap-2 mb-3">
                            {
                                Object.keys(filters).map((filter) => (
                                    <Badge key={uuidv4()} className="mx-1" onClick={() => handleClearFilter(filter)}>
                                        {filters[filter]}
                                        <FontAwesomeIcon icon={faXmark} className="ms-2" />
                                    </Badge>
                                ))
                            }
                        </div>
                        <div className="w-100 d-flex justify-content-end mb-2">
                            {
                                users.map((user) => (
                                    <Badge key={uuidv4()} className="mx-1" bg="success">
                                        <Stack direction="horizontal">
                                            <FontAwesomeIcon icon={faPerson} className="me-2" />
                                            <Stack gap={1}>
                                                {user.name}
                                                <small className="text-muted">Precinct Leader</small>
                                            </Stack>
                                        </Stack>
                                    </Badge>
                                ))
                            }
                        </div>
                        {!filters.province && renderTable(provinces, "province")}
                        {!filters.district && filters.province && renderTable(districts, "district")}
                        {!filters.muniCity && filters.district && filters.province && renderTable(muniCities, "muniCity")}
                        {!filters.barangay && filters.muniCity && filters.district && filters.province && renderTable(barangays, "barangay")}
                        {!filters.precinct && filters.barangay && filters.muniCity && filters.district && filters.province && renderTable(convertPgArray(user?.precincts) || precincts, "precinct")}
                        {
                            !voterId && filters.precinct && voters && <Table striped bordered hover>
                                <tbody>
                                    {
                                        voters.map((voter) => (
                                            <tr key={uuidv4()} onClick={() => setVoterId(voter.voter_id)}>
                                                <td>
                                                    {voter.name}
                                                    <Badge bg="success" className="mx-1">
                                                        {voter.voter_responses.length}
                                                    </Badge>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        }
                        {
                            voterId && <GatherForm voterId={voterId} onFinish={() => setVoterId(null)} />
                        }
                    </Card.Body>
                </Card>
            </Container>
        </section>
    )
}

export default Gather;